import React from "react";
import { EsmShRoot } from "../../components/esmshroot";
import "./post.css";
import "../../../static/markdown.css";
import "../../../static/code.css";

export default function (props: { pageContext: any }) {
  return <MdxContent {...props.pageContext} />
}

function MdxContent(props: { name: string, code: string }) {

  const { name, code } = props;

  return <>
    <h1>{name}</h1>
    <EsmShRoot src={code} />
    <pre className="import-hint" onClick={onClickSelect} >{
`import {} from "/static/${name}.mdx.js";`
    }</pre>
  </>
}

function onClickSelect(e: any){
  const target = e.target;
  navigator.clipboard.writeText(target.innerText);
  target.classList.toggle('copy');
  requestAnimationFrame(() => {
    target.classList.toggle('copy');
  });
}