import React from "react";
import Helmet from "react-helmet";

export function EsmShRoot(props: { src: string }) {
    const { src } = props;

    const scriptContent = `
    import MDXContent from "${src}";
    import React from "https://esm.sh/react@17";
    import ReactDOM from "https://esm.sh/react-dom@17";
    import 'https://esm.sh/regenerator-runtime/runtime';

    const article = document.querySelector('article');
    try{
        const root = React.createElement(MDXContent);
        ReactDOM.render(root, article);
    }catch(e){
        const root = React.createElement('pre', null, (e && e.message) || '');
        ReactDOM.render(root, article);
    }finally{
        article.onUnmount = () => {
            delete article.onUnmount;
            ReactDOM.unmountComponentAtNode(article);
        }
    }
`;

    const ref = React.useRef<HTMLDivElement>();

    React.useLayoutEffect(() => {
        const article = ref.current;
        if(!article)return;

        const doc = article.getRootNode() as Document;
        const script = doc.createElement('script');
        script.type = 'module';
        script.innerText = scriptContent;

        article.innerHTML = '';
        article.append(script);

        return function(){
            const { onUnmount } = article as any;
            if (onUnmount) {
                onUnmount();
            }
        }
    }, [ref, scriptContent]);

    const helmet = React.createElement(Helmet, null,
        React.createElement('link', { rel: 'modulepreload', href: 'https://esm.sh/react@17/jsx-runtime' }),
        React.createElement('link', { rel: 'modulepreload', href: 'https://esm.sh/react@17' }),
        React.createElement('link', { rel: 'modulepreload', href: 'https://esm.sh/react-dom@17' }),
    );
    return React.createElement('article', { className: "markdown-body", ref }, 'Loading...', helmet);
}